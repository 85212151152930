var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { AlignmentType, ImageRun, Paragraph, TabStopPosition, Table, TableCell, TableLayoutType, TableRow, TextRun, VerticalAlign, WidthType } from "docx";
function createLine(text, bold) {
    return new Paragraph({
        children: [
            new TextRun({ text: text, bold: bold })
        ],
        alignment: AlignmentType.CENTER
    });
}
function createBox(children) {
    return new Table({
        rows: [
            new TableRow({
                children: [
                    new TableCell({
                        children: children,
                        verticalAlign: VerticalAlign.CENTER
                    })
                ]
            })
        ],
        width: {
            size: '100%',
            type: WidthType.PERCENTAGE,
        },
        layout: TableLayoutType.FIXED,
        columnWidths: [TabStopPosition.MAX]
    });
}
export function createTitlePage(config) {
    return {
        children: [
            createBox(__spreadArray(__spreadArray([
                new Paragraph(""),
                new Paragraph({
                    children: [
                        config.company.logo ? new ImageRun({
                            data: config.company.logo,
                            transformation: {
                                width: 100,
                                height: 100
                            },
                        }) : new TextRun("")
                    ],
                    alignment: AlignmentType.CENTER
                }),
                new Paragraph(""),
                createLine(config.company.name, true),
                createLine(config.company.address1, true),
                createLine(config.company.address2, true),
                createLine(config.company.email + " - " + config.company.phone, true)
            ], (config.company.web ? [createLine(config.company.web, true)] : []), true), [
                new Paragraph("")
            ], false)),
            new Paragraph(""),
            createBox(__spreadArray(__spreadArray(__spreadArray([], (config.object_type ? [
                createLine("".concat(config.labels.locationDescription, " - ").concat(config.objectType), true),
                createLine("".concat(config.labels.goal, " - ").concat(config.reportType), true),
            ] : []), true), (config.info ? [
                createLine(config.info),
                createLine(config.assignmentName),
            ] : []), true), (config.assignmentAddress1 ? [
                createLine(config.assignmentAddress1),
                createLine(config.assignmentAddress2),
            ] : []), true)),
            new Paragraph(""),
            createBox([
                createLine(config.name, true),
                createLine(config.address1, true),
                createLine(config.address2, true)
            ]),
            new Paragraph(""),
            createBox([
                createLine(config.reference ? "".concat(config.labels.reference, ": ").concat(config.reference) : ""),
                createLine(""),
                createLine(config.executor ? "".concat(config.labels.preparedBy, ": ").concat(config.executor) : ""),
                createLine(config.executor ? "".concat(config.labels.executedBy, ": ").concat(config.executor) : ""),
                createLine(config.executionDate ? "".concat(config.labels.executedOn, ": ").concat(config.executionDate) : ""),
                createLine(""),
                createLine(config.party1.name && config.party1.role ? "".concat(config.party1.role, ": ").concat(config.party1.name) : ""),
                createLine(config.party2.name && config.party2.role ? "".concat(config.party2.role, ": ").concat(config.party2.name) : ""),
            ]),
            new Paragraph({
                children: [
                    config.generalImages.length ? new ImageRun({
                        data: config.generalImages[0].data,
                        transformation: {
                            width: 250,
                            height: 250
                        },
                    }) : new TextRun("")
                ],
                spacing: {
                    before: 200,
                    after: 200
                },
                alignment: AlignmentType.CENTER
            })
        ]
    };
}
