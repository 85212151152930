import { Container } from "@mui/material";
import { getInspectionImageURL } from "inspector-common/lib/api/hooks/inspections/useGetInspectionImageURL";
import { useLanguage } from "inspector-common/lib/language";
import { useLocation, useMatch } from "react-router-dom";

interface ImagePreviewPageProps {
}

export default function ImagePreviewPage(props: ImagePreviewPageProps) {
    const match = useMatch("/image/:assignmentId/:inspectionId/:imageId")

    const assignmentId = match?.params.assignmentId;
    const inspectionId = match?.params.inspectionId;
    const imageId = match?.params.imageId

    if (!assignmentId || !inspectionId || !imageId) {
        return <>404: Not found</>
    }

    let url = window.location.origin;
    if (window.location.origin.endsWith(":3001")) {
        url = "https://dev.loci.expert"
    } else if (window.location.origin.endsWith(":3002")) {
        url = "https://loci.expert"
    }

    return <Container sx={{textAlign: "center"}}><img src={`${url}/inspection_images/${assignmentId}/${inspectionId}/${imageId}`} /></Container>

}