var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { DocumentLanguage } from "../model";
import { InspectionType } from "../model/inspection/InspectionType";
import { formatAddressLine1, formatAddressLine2, getDateString } from "../utils/utils";
import labels from "./labels";
import { defaultTerms } from "../model/inspection/create";
import { renderTerms } from "../utils/inspection";
export function generateRenderConfig(company, assignment, inspection, language, signatures, executor, documentLanguage, imageMap, logoBase64) {
    var _a, _b, _c, _d, _e, _f;
    var roomConfig = generateRoomRenderConfig(assignment.identifier, inspection.identifier, inspection.root_node, [], imageMap, documentLanguage);
    var rootNodeConfig = roomConfig[0];
    var rooms = roomConfig.slice(1);
    var reportType = Object.values(InspectionType).includes(inspection.inspection_type) ?
        language.inspection.inspectionType[inspection.inspection_type] : inspection.inspection_type;
    var reference = ((_a = assignment.reference) !== null && _a !== void 0 ? _a : getDateString((_b = inspection.start_date) !== null && _b !== void 0 ? _b : new Date())
        .replaceAll("/", ".").replaceAll(" ", "") + "-"
        + assignment.name.slice(0, 3).toUpperCase()
        + inspection.identifier.slice(0, 3).toUpperCase());
    var terms = renderTerms((_c = inspection.terms) !== null && _c !== void 0 ? _c : defaultTerms, inspection, assignment, company, executor);
    return {
        assignmentName: assignment.name,
        name: inspection.name,
        company: __assign(__assign({}, company), { logo: logoBase64, logoSmall: logoBase64, address1: formatAddressLine1(company.address), address2: formatAddressLine2(company.address), web: company.web }),
        labels: labels[documentLanguage],
        assignmentAddress1: formatAddressLine1(assignment.address),
        assignmentAddress2: formatAddressLine2(assignment.address),
        address1: formatAddressLine1((_d = inspection.address) !== null && _d !== void 0 ? _d : assignment.address),
        address2: formatAddressLine2((_e = inspection.address) !== null && _e !== void 0 ? _e : assignment.address),
        info: assignment.info,
        executor: executor,
        party1: {
            role: inspection.party1.role,
            name: inspection.party1.name,
            hasRepresentative: !!inspection.party1.representative,
            representative: inspection.party1.representative,
            address1: formatAddressLine1(inspection.party1.address),
            address2: formatAddressLine2(inspection.party1.address)
        },
        party2: {
            role: inspection.party2.role,
            name: inspection.party2.name,
            hasRepresentative: !!inspection.party2.representative,
            representative: inspection.party2.representative,
            address1: formatAddressLine1(inspection.party2.address),
            address2: formatAddressLine2(inspection.party2.address)
        },
        extraParties: inspection.extra_parties.map(function (party) {
            return __assign(__assign({}, party), { address1: formatAddressLine1(party.address), address2: formatAddressLine2(party.address) });
        }),
        executionDate: getDateString((_f = inspection.start_date) !== null && _f !== void 0 ? _f : assignment.plan_date),
        readableDate: getReadableDate(new Date(), documentLanguage),
        reference: reference,
        objectType: inspection.object_type.replace("NL", "").replace("FR", "").replace("EN", ""),
        reportType: reportType,
        intro: terms.general,
        rooms: rooms,
        signatures: signatures,
        signatureHeader: terms.signature_text_header,
        signatureItems: terms.signature_text_items,
        clauses: terms.clauses,
        remarks: terms.remarks,
        inspectionRemarks: inspection.root_node.remarks,
        generalImages: rootNodeConfig.images,
    };
}
function isNotEmpty(node) {
    return (node.image_references.length > 0 ||
        (node.observations.length > 0 && node.observations.filter(function (o) { return isNoEmptyObservation(o); }).length > 0) ||
        node.children.filter(isNotEmpty).length > 0 ||
        node.remarks.length > 0 ||
        (node.state !== null && node.state !== undefined) ||
        node.attributes.filter(function (attribute) { return !["", "-"].includes(attribute.value.trim()); }).length > 0);
}
function isNoEmptyObservation(observation) {
    return observation.images.length > 0 || observation.remarks.length > 0 || !["", "-"].includes(observation.type);
}
function getImageLink(assignmentId, inspectionId, imageId) {
    var _a, _b;
    return "".concat((_b = (_a = window === null || window === void 0 ? void 0 : window.location) === null || _a === void 0 ? void 0 : _a.origin) !== null && _b !== void 0 ? _b : "https://loci.expert/", "#/image/").concat(assignmentId, "/").concat(inspectionId, "/").concat(imageId);
}
function generateRoomRenderConfig(assignmentId, inspectionId, node, path, imageMap, documentLanguage) {
    var _a, _b;
    var rooms = [];
    var config = {
        title: path.join(".") + " " + getNodeName(node),
        level: path.length,
        level1: path.length < 2,
        level2: path.length == 2,
        level3: path.length > 2,
        hasState: !!node.state,
        state: (_b = labels[documentLanguage].state[(_a = node.state) !== null && _a !== void 0 ? _a : "dummy"]) !== null && _b !== void 0 ? _b : node.state,
        hasAttributes: node.attributes.filter(function (a) { return !["", "-"].includes(a.value); }).length > 0,
        attributes: node.attributes.filter(function (a) { return !["", "-"].includes(a.value); }),
        hasImages: node.image_references.length > 0,
        images: node.image_references.map(function (image) {
            var _a, _b, _c, _d, _e, _f;
            return {
                data: (_b = (_a = imageMap.get(image.identifier)) === null || _a === void 0 ? void 0 : _a.data) !== null && _b !== void 0 ? _b : "",
                width: (_d = (_c = imageMap.get(image.identifier)) === null || _c === void 0 ? void 0 : _c.width) !== null && _d !== void 0 ? _d : 0,
                height: (_f = (_e = imageMap.get(image.identifier)) === null || _e === void 0 ? void 0 : _e.height) !== null && _f !== void 0 ? _f : 0,
                date: image.copied_from_assignment_id ? image.date : null,
                description: image.description,
                link: getImageLink(assignmentId, inspectionId, image.identifier)
            };
        }),
        remarks: node.remarks,
        observations: node.observations.filter(function (o) { return isNoEmptyObservation(o); }).map(function (observation) {
            return {
                type: observation.type,
                remarks: observation.remarks,
                hasImages: observation.images.length > 0,
                images: observation.images.map(function (image) {
                    var _a, _b, _c, _d, _e, _f;
                    return {
                        data: (_b = (_a = imageMap.get(image.identifier)) === null || _a === void 0 ? void 0 : _a.data) !== null && _b !== void 0 ? _b : "",
                        width: (_d = (_c = imageMap.get(image.identifier)) === null || _c === void 0 ? void 0 : _c.width) !== null && _d !== void 0 ? _d : 0,
                        height: (_f = (_e = imageMap.get(image.identifier)) === null || _e === void 0 ? void 0 : _e.height) !== null && _f !== void 0 ? _f : 0,
                        date: image.date,
                        description: image.description,
                        link: getImageLink(assignmentId, inspectionId, image.identifier)
                    };
                }),
            };
        })
    };
    rooms.push(config);
    node.children.filter(isNotEmpty).forEach(function (child, index) {
        rooms.push.apply(rooms, generateRoomRenderConfig(assignmentId, inspectionId, child, path.concat(index + 1), imageMap, documentLanguage));
    });
    return rooms;
}
function getNodeName(node) {
    return node.name;
}
export function getAllInspectionImagesRecursive(node) {
    var nodeImages = [];
    node.image_references.forEach(function (image) {
        nodeImages.push(image);
    });
    node.observations.forEach(function (observation) {
        observation.images.forEach(function (image) {
            nodeImages.push(image);
        });
    });
    node.children.forEach(function (child) {
        nodeImages.push.apply(nodeImages, getAllInspectionImagesRecursive(child));
    });
    return nodeImages;
}
;
export function getPostProcessingSignatures(signatures, inspection) {
    var _a, _b, _c, _d, _e, _f, _g;
    var signaturesPerPage = 7;
    var offsetFirstPage = 610;
    var otherOffset = 670;
    var blockHeight = 100;
    var offsetX = 300;
    var amountOfExtraSignatures = ((_a = signatures.extraSignatures) !== null && _a !== void 0 ? _a : []).length;
    var amountOfRequiredSignatures = 3 + ((_b = inspection.extra_parties) !== null && _b !== void 0 ? _b : []).length;
    var fullSignatureArray = __spreadArray(__spreadArray([
        (_c = signatures === null || signatures === void 0 ? void 0 : signatures.signatureParty1) !== null && _c !== void 0 ? _c : null,
        (_d = signatures === null || signatures === void 0 ? void 0 : signatures.signatureParty2) !== null && _d !== void 0 ? _d : null
    ], (((_e = signatures === null || signatures === void 0 ? void 0 : signatures.extraSignatures) !== null && _e !== void 0 ? _e : []).length > 0 ? (_f = signatures === null || signatures === void 0 ? void 0 : signatures.extraSignatures) !== null && _f !== void 0 ? _f : [] : new Array(amountOfRequiredSignatures - amountOfExtraSignatures - 3).fill(null)), true), [
        (_g = signatures === null || signatures === void 0 ? void 0 : signatures.signatureAssignee) !== null && _g !== void 0 ? _g : null
    ], false);
    var amountOfSignaturePages = amountOfRequiredSignatures <= signaturesPerPage - 1 ? 1 : 2;
    return fullSignatureArray.flatMap(function (s, index) {
        var pageNumber = -amountOfSignaturePages + (index < (signaturesPerPage - 1) ? 0 : Math.floor((index - (signaturesPerPage - 1)) / signaturesPerPage) + 1);
        if (s === null || s === undefined || s.length === 0) {
            return [{
                    page: pageNumber,
                    x: offsetX,
                    y: index < (signaturesPerPage - 1) ? offsetFirstPage - blockHeight * index : otherOffset - blockHeight * ((index - signaturesPerPage + 1) % signaturesPerPage)
                }];
        }
        else {
            return [];
        }
    });
}
function getReadableDate(d, language) {
    var getMonthLabels = function (language) {
        var m = new Map();
        switch (language) {
            case DocumentLanguage.NL:
                m.set(1, "Januari");
                m.set(2, "Februari");
                m.set(3, "Maart");
                m.set(4, "April");
                m.set(5, "Mei");
                m.set(6, "Juni");
                m.set(7, "Juli");
                m.set(8, "Augustus");
                m.set(9, "September");
                m.set(10, "Oktober");
                m.set(11, "November");
                m.set(12, "December");
                return m;
            case DocumentLanguage.FR:
                m.set(1, "Janvier");
                m.set(2, "Février");
                m.set(3, "Mars");
                m.set(4, "Avril");
                m.set(5, "Mai");
                m.set(6, "Juin");
                m.set(7, "Juillet");
                m.set(8, "Août");
                m.set(9, "Septembre");
                m.set(10, "Octobre");
                m.set(11, "Novembre");
                m.set(12, "Dacembre");
                return m;
            default:
                m.set(1, "January");
                m.set(2, "February");
                m.set(3, "March");
                m.set(4, "April");
                m.set(5, "May");
                m.set(6, "June");
                m.set(7, "July");
                m.set(8, "August");
                m.set(9, "September");
                m.set(10, "October");
                m.set(11, "November");
                m.set(12, "December");
                return m;
        }
    };
    return "".concat(d.getDate(), " ").concat(getMonthLabels(language).get(d.getMonth() + 1), " ").concat(d.getFullYear());
}
