import { Button, Dialog, DialogContent, DialogTitle, Stack, Typography } from "@mui/material"
import { useLanguage } from "inspector-common/lib/language"
import { Inspection, InspectionNode } from "inspector-common/lib/model"

interface MoveDialogProps {
    open: boolean
    setOpen: (open: boolean) => void
    moveNode: (newPath: number[]) => void
    inspection: Inspection
    movingPath: number[]
}

const styles = {
    button: {
        minWidth: "300px",
        justifyContent: "flex-start",
        borderRadius: 0,
        border: "1px solid lightgrey",
    }
}
export default function MoveDialog(props: MoveDialogProps) {
    const language = useLanguage();

    return <Dialog open={props.open} onClose={() => props.setOpen(false)}>
        <DialogTitle>
            <Typography>{language.inspection.moveNode}</Typography>
        </DialogTitle>
        <DialogContent>
            <NodeHeaders 
                node={props.inspection.root_node}
                path={[]}
                moveNode={(newPath: number[]) => {props.moveNode(newPath); props.setOpen(false)}}
                movingPath={props.movingPath}
            />
        </DialogContent>
    </Dialog>
}

interface NodeHeadersProps {
    node: InspectionNode
    path: number[]
    moveNode: (newPath: number[]) => void
    movingPath: number[]
}

function NodeHeaders(props: NodeHeadersProps) {
    if (props.path.slice(0, props.movingPath.length).toString() === props.movingPath.toString()) {
        return <></>
    }
    return <Stack direction="column" spacing={0} pl={1}>
        <Button 
            variant="text" 
            onClick={() => props.moveNode(props.path)}
            sx={styles.button}
        >{props.path.length > 0 ? props.node.name : "/"}</Button>
        {
            props.node.children.map((child, index) => {
                return <NodeHeaders 
                    key={props.path.toString()}
                    node={child}
                    path={props.path.concat(index)}
                    moveNode={props.moveNode}
                    movingPath={props.movingPath}
                />
            })
        }
    </Stack>
}